import { TokenService } from './token.service';



import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';


@Injectable()

export class AuthService {
    status = "unauthenticated";
    authData = null;
    confirmationSource;
    resetEmail;
    isReset = false;
    constructor(private http: HttpClient, private tokenService: TokenService) {
        this.authData = this.tokenService.getToken();
        console.log(this.authData);
    }

    register(name: string, email: string, password: string) {
        let obj = {
            name: name,
            email: email,
            password: password
        };
        console.log(obj);
        return this.http.post(`${environment.apiUrl}/signup`, obj).pipe(
            map(
                (user: any) => {
                    localStorage.setItem('caren-web', JSON.stringify(user));
                    this.authData = user;
                    // this.currentUserSubject.next(user);
                    return user;
                }
            )
        );
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/signin`, { email, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('caren-web', JSON.stringify(user));
                this.authData = user;
                // this.currentUserSubject.next(user);
                return user;
            }));
    }


    sendMail() {
        let obj = {
            email: this.authData.user.email,
        };
        return this.http.post(`${environment.apiUrl}/send-email`, obj);
    }

    resetEmailID(email) {
        let obj = {
            email: email
        }
        return this.http.post(`${environment.apiUrl}/reset-email`, obj);
    }

    resetPassword(password){
        let obj = {
            email: this.resetEmail,
            password: password
        }
        return this.http.put(`${environment.apiUrl}/reset-password`, obj);
    }

    getResetEmail() {
        return this.resetEmail;
    }

    activateCode(code) {
        let obj = {
            email: this.authData.user.email,
            auth_code: code
        };
        console.log(obj);
        return this.http.post(`${environment.apiUrl}/activate`, obj);
    }

    activateCodeResetPassword(code) {
        let obj = {
            email: this.resetEmail,
            reset_code: code
        };
        console.log(obj);
        return this.http.post(`${environment.apiUrl}/reset-verify`, obj).pipe(
            map(data=>{
                this.isReset = true;
                return data;
            })
        );
    }

    checkUserActivation() {
        return this.http.get(`${environment.apiUrl}/check-user-activation`).pipe(
            map(
                (data: any) => {
                    console.log(data);
                    if (data.user.status == "unverified") {
                        this.setUserUnverified();
                    }
                    else {
                        this.confirmationSource = "login";
                        this.status = "authenticated";
                    }
                    return data;
                }
            )
        );
    }

    logout() {
        // remove user from local storage to log user out
        this.authData = null;
        this.tokenService.removeToken();
        status = "unauthenticated";
        this.resetEmail = this.confirmationSource = this.isReset= null;
        // this.currentUserSubject.next(null);
    }

    getToken() {
        console.log(this.authData);
        return this.authData ? this.authData.user.token : null;
    }

    setUserUnverified() {
        this.confirmationSource = "login";
        this.status = "unverified";
    }

    getConfirmationSource() {
        return this.confirmationSource;
    }

    setUserUnauthenticated() {
        this.confirmationSource = null;
        this.status = "unauthenticated";
    }

    setEmailForReset(email) {
        this.resetEmail = email;
    }

    isResetPasswordAllowed(){
        return this.isReset;
    }

}


       