import { Component } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'aws-two-columns-layout',
  styleUrls: ['./aws-two-columns.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="one-third">
      </nb-layout-column>

      <nb-layout-column class="two-third">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>

    </nb-layout>
  `,
})
export class AwsTwoColumnsLayoutComponent {}
