


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    loadResidientPolicyTypeList = new Subject<null>();
    loadResidientPolicyRuleList = new Subject<null>();
    loadVisitorListsing = new Subject<null>();
    loadDepartments = new Subject<null>();
    loadPositions = new Subject<null>();
    loadPolicyTypeList = new Subject<null>();
    loadBreakPolicyTypeList = new Subject<null>();
    loadChecklistPolicyCategoriesList = new Subject<null>();
    loadChecklistPolicyTypeList = new Subject<null>();
    loadChecklistPolicyRuleList = new Subject<null>();
    loadOvertimePolicyTypeList = new Subject<null>();
    loadBenefitsPolicyTypeList = new Subject<null>();
    loadTaxesPolicyTypeList = new Subject<null>();
    loadNotificationsPolicyTypeList = new Subject<null>();
    loadGeofencePolicyTypeList = new Subject<null>();
    loadGeotrackingPolicyTypeList = new Subject<null>();
    loadAvailabilityPolicyTypeList = new Subject<null>();
    loadCheckinPolicyTypeList = new Subject<null>();
    loadCheckoutPolicyTypeList = new Subject<null>();
    loadPolicySalaryRuleList = new Subject<null>();
    loadPolicyTimeOffRuleList = new Subject<null>();
    loadBreakRuleList = new Subject<null>();
    loadOvertimeRuleList = new Subject<null>();
    loadBenefitsRuleList = new Subject<null>();
    loadTaxesRuleList = new Subject<null>();
    loadNotificationsRuleList = new Subject<null>();
    loadGeofenceRuleList = new Subject<null>();
    loadGeotrackingRuleList = new Subject<null>();
    loadAvailibilityRuleList = new Subject<null>();
    loadCheckinRuleList = new Subject<null>();
    loadCheckoutRuleList = new Subject<null>();
    loadNotificationsOfPayrate = new Subject<null>();
    loadNotificationsOfTimeOff = new Subject<null>();
    loadNotificationsOfBreak = new Subject<null>();
    loadNotificationsOfOvertime = new Subject<null>();
    loadNotificationsOfBenefits = new Subject<null>();
    loadNotificationsOfTaxes = new Subject<null>();
    loadNotificationsOfNotifications = new Subject<null>();
    loadNotificationsOfGeofence = new Subject<null>();
    loadNotificationsOfGeotracking = new Subject<null>();
    loadNotificationsOfAvailibility = new Subject<null>();
    loadNotificationsOfCheckin = new Subject<null>();
    loadNotificationsOfCheckout = new Subject<null>();
    loadNotificationsOfApprovals = new Subject<null>();
    loadRolesListing = new Subject<null>();
    loadEmployeesListing = new Subject<null>();
    loadAssignedPoliciesListing = new Subject<null>();
    loadWorkWeekListing = new Subject<null>();
    hideHeaderOfCompany = new Subject<null>();
    hideSidebarOfCompany = new Subject<null>();
    showSidebarOfCompany = new Subject<null>();
    showBreakTimer = new Subject<any>();
    currentPageBeforeLogin = new Subject<any>();
    loadProvidersListing = new Subject<null>();
    loadBenefitsTypesListing = new Subject<null>();
    loadTrackingMapPins = new Subject<any>();
    fromReviewContractheader  = new Subject<any>();
    fromsendContractheader = new Subject<any>();
    loadContractsListing = new Subject<any>();
    visitorList = new Subject<any>();
    printerField = new Subject<any>();
    loadIncidentPolicyTypeList = new Subject<null>();
    loadIncidentPolicyRuleList = new Subject<null>();
    loadSpaceAvailability = new Subject<null>();
    loadSpaceAreas = new Subject<null>();
    loadFloorAreas = new Subject<null>();
    loadBuildingAvailability = new Subject<null>();
    loadFloorAvailability = new Subject<null>();
    loadFacilitySpaces = new Subject<null>();
    loadAminityList = new Subject<null>();
    loadFloorListing = new Subject<null>();
    loadComplexList = new Subject<null>();
    checkService = new Subject<null>();
    gridCardService = new Subject<null>();
    buildingService = new Subject<null>();
    loadResourceTypes = new Subject<null>();
    loadResrouceCapacity = new Subject<null>();
    loadRoomCapacity = new Subject<null>();
    loadflooravailability = new Subject<null>();
    loadfloorSpaces = new Subject<null>();
    loadfloorSpacesAreas = new Subject<null>();
    loadSpaceAvailibility = new Subject<null>();
    loadSpaceAreaAvailability = new Subject<null>();
    loadSpaceAreaResources = new Subject<null>();
    loadFloorAreasList = new Subject<null>();
    loadFloorAreasAvailability = new Subject<null>();
    loadFloorAreasResources = new Subject<null>();
    loadAminityFilter = new Subject<null>();
    loadMeetingRoomFilter = new Subject<null>();
    loadResourceFilter = new Subject<null>();
    sideBarStatus = new Subject<any>();
    globalUserListing = new Subject<any>();
    loadAppUsers = new Subject<any>();
    loadAppGroups = new Subject<any>();
    loadKiosk = new Subject<null>();
    loadKiosktype = new Subject<null>();
    loadKioskruleList = new Subject<null>();
    loadMainPayrol = new Subject<null>();
    loadPayrolFitered = new Subject<null>();
    loadFinalPayrol = new Subject<null>();
    sideBarDataChanges(value){
        this.sideBarStatus.next(value);
    }
    constructor(){

    }
}


