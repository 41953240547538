import { AuthGuard } from './@core/_helpers/auth.guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    redirectTo: "applications",
  },
  {
    path: 'applications',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/applications/applications.module')
      .then(m => m.ApplicationsModule)
  },
  // {
  //   path: 'shiftSchedule',
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./applications/shift-schedule/shift-schedule.module')
  //     .then(m => m.ShiftScheduleModule)
  // },
  {
    path: 'globalSettings',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/applications/global-admin-settings/global-admin-settings.module')
      .then(m => m.GlobalAdminSettingsModule)
  },
  {
    path: 'companyLocations',
    loadChildren: () => import('./applications/applications/global-admin-settings/company-main-location/company-main-location.module')
      .then(m => m.CompanyMainLocationModule)
  },
  {
    path: 'facilityManagement',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/facility_management/facility-management/facility-management.module')
      .then(m => m.FacilityManagementModule)
  },
  {
    path: 'facilityPermissions',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/facility_management/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'facilityPolicices',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/facility_management/facility-policiecs/facility-policiecs.module')
      .then(m => m.FacilityPoliciecsModule)
  },
  {
    path: 'facilityNotifications',
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/facility_management/facility-notifications/facility-notifications.module')
      .then(m => m.FacilityNotificationsModule)
  },
  // {
  //   path: 'dynamicScheduler',
  //   canLoad: [AuthGuard],
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('./applications/Dynamic Scheduler/facility-management/facility-management.module')
  //     .then(m => m.FacilityManagementModule)
  // },
  {
    path: 'selfServicePortal',
    loadChildren: () => import('./applications/self-service-portal/self-service-portal.module')
      .then(m => m.SelfServicePortalModule)
  },
  {
    path: 'bookableResources',
    loadChildren: () => import('./applications/facility_management/bookable-resources/bookable-resources.module')
      .then(m => m.BookableResourcesModule)
  },
  {
    path: 'facilityKiosk',
    loadChildren: () => import('./applications/facility_management/facility-kiosk/facility-kiosk.module')
      .then(m => m.FacilityKioskModule)
  },
  {
    path: 'expenseManagement',
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/expense-management/expense-management.module')
      .then(m => m.ExpenseManagementModule),
    data: { pathName: 'Expense Management' },
  },
  {
    path: 'expenseSettings',
    loadChildren: () => import('./applications/expense-management/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'expenseNotifications',
    loadChildren: () => import('./applications/expense-management/expense-notifications/expense-notifications.module')
      .then(m => m.ExpenseNotificationsModule)
  },
  {
    path: 'expensePolicies',
    loadChildren: () => import('./applications/expense-management/expense-policies/expense-policies.module')
      .then(m => m.ExpensePoliciesModule)
  },
  {
    path: 'expensePermissions',
    loadChildren: () => import('./applications/expense-management/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'meetings',
    loadChildren: () => import('./applications/meetings/meetings.module')
      .then(m => m.MeetingsModule)
  },
  {
    path: 'meetingNotifications',
    loadChildren: () => import('./applications/meetings/meetings-notifcations/meetings-notifcations.module')
      .then(m => m.MeetingsNotifcationsModule)
  },
  {
    path: 'meetingPolicies',
    loadChildren: () => import('./applications/meetings/meeting-policies/meeting-policies.module')
      .then(m => m.MeetingPoliciesModule)
  },
  {
    path: 'meetingConfiguration',
    loadChildren: () => import('./applications/meetings/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'visitorManagement',
    loadChildren: () => import('./applications/visitors/visitors.module')
      .then(m => m.VisitorsModule)
  },
  {
    path: 'visitorPermissions',
    loadChildren: () => import('./applications/visitors/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'visitorPolicies',
    loadChildren: () => import('./applications/visitors/visitor-policies/visitor-policies.module')
      .then(m => m.VisitorPoliciesModule)
  },
  {
    path: 'visitorNotifications',
    loadChildren: () => import('./applications/visitors/visitor-notifications/visitor-notifications.module')
      .then(m => m.VisitorNotificationsModule)
  },
  {
    path: 'complianceManagement',
    loadChildren: () => import('./applications/compliance/compliance.module')
      .then(m => m.ComplianceModule)
  },
  {
    path: 'complianceSettings',
    loadChildren: () => import('./applications/compliance/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'compliancePolicies',
    loadChildren: () => import('./applications/compliance/compliance-policies/compliance-policies.module')
      .then(m => m.CompliancePoliciesModule)
  },
  {
    path: 'compliancePermissions',
    loadChildren: () => import('./applications/compliance/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'complianceNotification',
    loadChildren: () => import('./applications/compliance/compliance-notifications/compliance-notifications.module')
      .then(m => m.ComplianceNotificationsModule)
  },
  {
    path: 'contacTracing',
    loadChildren: () => import('./applications/contact_Tracing/main-contact-tracing.module')
      .then(m => m.MainContactTracingModule)
  },
  {
    path: 'contacTracingPolicies',
    loadChildren: () => import('./applications/contact_Tracing/contact-tracing-policies/contact-tracing-policies.module')
      .then(m => m.ContactTracingPoliciesModule)
  },
  {
    path: 'contactTracingNotifications',
    loadChildren: () => import('./applications/contact_Tracing/contact-tracing-notifications/contact-tracing-notifications.module')
      .then(m => m.ContactTracingNotificationsModule)
  },
  {
    path: 'contactTracingPermissions',
    loadChildren: () => import('./applications/contact_Tracing/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'contractManagement',
    loadChildren: () => import('./applications/Contracts/contract-management.module')
      .then(m => m.ContractManagementModule)
  },
  {
    path: 'contractNotification',
    loadChildren: () => import('./applications/Contracts/contract-notifications/contract-notifications.module')
      .then(m => m.ContractNotificationsModule)
  },
  {
    path: 'contractPermissioins',
    loadChildren: () => import('./applications/Contracts/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'contractPolicies',
    loadChildren: () => import('./applications/Contracts/contract-policies/contract-policies.module')
      .then(m => m.ContractPoliciesModule)
  },
  // {
  //   path: 'benefitManagement',
  //   loadChildren: () => import('./applications/Benefits/benefit-management.module')
  //     .then(m => m.BenefitManagementModule)
  // },
  // {
  //   path: 'benefitSettings',
  //   loadChildren: () => import('./applications/Benefits/hrsetting/hrsetting.module')
  //     .then(m => m.HrsettingModule)
  // },
  // {
  //   path: 'benefitPolicies',
  //   loadChildren: () => import('./applications/Benefits/benefit-policies/benefit-policies.module')
  //     .then(m => m.BenefitPoliciesModule)
  // },
  // {
  //   path: 'benefitNotifications',
  //   loadChildren: () => import('./applications/Benefits/main-notifications/main-notifications.module')
  //     .then(m => m.MainNotificationsModule)
  // },
  // {
  //   path: 'benefitPermissions',
  //   loadChildren: () => import('./applications/Benefits/configurations/configurations.module')
  //     .then(m => m.ConfigurationsModule)
  // },
  {
    path: 'recruitmentManagement',
    loadChildren: () => import('./applications/Recruitment/recruitment-management.module')
      .then(m => m.RecruitmentManagementModule)
  },
  {
    path: 'recruitmentPermissions',
    loadChildren: () => import('./applications/Recruitment/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'recruitmentPolicies',
    loadChildren: () => import('./applications/Recruitment/recruitment-policies/recruitment-policies.module')
      .then(m => m.RecruitmentPoliciesModule)
  },
  {
    path: 'recruitmentNotifications',
    loadChildren: () => import('./applications/Recruitment/recruitment-notifications/recruitment-notifications.module')
      .then(m => m.RecruitmentNotificationsModule)
  },
  {
    path: 'incidientManagement',
    loadChildren: () => import('./applications/incidents-management/incidents-management.module')
      .then(m => m.IncidentsManagementModule)
  },
  {
    path: 'incidientPolicies',
    loadChildren: () => import('./applications/incidents-management/incidents/incidents.module')
      .then(m => m.IncidentsModule)
  },
  {
    path: 'incidientNotifications',
    loadChildren: () => import('./applications/incidents-management/incidient-notifications-main/incidient-notifications-main.module')
      .then(m => m.IncidientNotificationsMainModule)
  },
  {
    path: 'incidientPermissions',
    loadChildren: () => import('./applications/incidents-management/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'trackingManager',
    loadChildren: () => import('./applications/Tracking/tracking.module')
      .then(m => m.TrackingModule)
  },
  {
    path: 'trackingPermissions',
    loadChildren: () => import('./applications/Tracking/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'trackingPolicies',
    loadChildren: () => import('./applications/Tracking/tracking-policies/tracking-policies.module')
      .then(m => m.TrackingPoliciesModule)
  },
  {
    path: 'trackingNotifications',
    loadChildren: () => import('./applications/Tracking/tracker-notification/tracker-notification.module')
      .then(m => m.TrackerNotificationModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('./applications/Tracking/tracking.module')
      .then(m => m.TrackingModule)
  },
  {
    path: 'Hrhub',
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/HR/Hr Hub/hr-hub.module')
      .then(m => m.HrHubModule),
    data: { pathName: 'Hr Management' },
  },
  {
    path: 'HrPolicies',
    loadChildren: () => import('./applications/HR/Policies/hr-policies.module')
      .then(m => m.HrPoliciesModule)
  },
  {
    path: 'hrNotifications',
    loadChildren: () => import('./applications/HR/hrnotifications/hrnotifications.module')
      .then(m => m.HrnotificationsModule)
  },
  {
    path: 'hrSettings',
    loadChildren: () => import('./applications/HR/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'hrRoles',
    loadChildren: () => import('./applications/HR/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'selfServiceDesk',
    loadChildren: () => import('./applications/Self-Service Desk/service-desk/service-desk.module')
      .then(m => m.ServiceDeskModule)
  },
  {
    path: 'ServiceConfiguration',
    loadChildren: () => import('./applications/Self-Service Desk/service-desk/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./applications/onboarding/onboarding-main.module')
      .then(m => m.OnboardingMainModule)
  },
  {
    path: 'onboardingPermissions',
    loadChildren: () => import('./applications/onboarding/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'onBoardingSettings',
    loadChildren: () => import('./applications/onboarding/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'offboarding',
    loadChildren: () => import('./applications/offboarding/offboarding-main.module')
      .then(m => m.OffboardingMainModule)
  },
  {
    path: 'offboardingSettings',
    loadChildren: () => import('./applications/offboarding/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'generalSettings',
    loadChildren: () => import('./applications/general-settings/general-main/general-main.module')
      .then(m => m.GeneralMainModule)
  },
  {
    path: 'talentPool',
    loadChildren: () => import('./applications/skills/skills-pool/skills-pool.module')
      .then(m => m.SkillsPoolModule)
  },
  {
    path: 'skillSettings',
    loadChildren: () => import('./applications/skills/hrsetting/hrsetting.module')
      .then(m => m.HrsettingModule)
  },
  {
    path: 'talentConfiguration',
    loadChildren: () => import('./applications/skills/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'contentBuilder',
    loadChildren: () => import('./applications/content-builder/content-builder.module')
      .then(m => m.ContentBuilderModule)
  },
  {
    path: 'offboardingPermissions',
    loadChildren: () => import('./applications/offboarding/configurations/configurations.module')
      .then(m => m.ConfigurationsModule)
  },
  {
    path: 'kiosk',
    loadChildren: () => import('./kiosk/kiosk.module')
      .then(m => m.KioskModule)
  },
  {
    path: 'employee-kiosk',
    loadChildren: () => import('./employee-kiosk/employee-kiosk.module')
      .then(m => m.EmployeeKioskModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing-pages/landing-pages.module')
      .then(m => m.LandingPagesModule),
  },
  { path: '**', redirectTo: '404-Not-Found' },
  {
    path: '404-Not-Found',
    loadChildren: () => import('./error-page/error-page.module')
      .then(m => m.ErrorPageModule),
  }


];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
