import { map, catchError } from 'rxjs/operators';
import { AuthService } from './../services/auth.service';
import { TokenService } from './../services/token.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';

@Injectable(
//   {
//   providedIn: 'root'
// }
)
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  authData;
  pathNameFromData = '';
  permissionsArray = [];
  permissionsArrayFiltered = [];
  permissionCreate;
  permissionView ;
  permissionUpdate ;
  permissionDelete ;
  returningUrl;
  constructor(private authService: AuthService , private router: Router, private tokenService: TokenService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.pathNameFromData =  next.data.pathName;
      console.log("canActivate next", next.data.pathName);
      console.log("canActivate state", state);
      console.log("canActivate state.url", state.url);

      this.authData =  this.tokenService.getToken();

      // ------ send back to login for null token
      console.log("window.location", window.location.hash.split('#')[1]);
      this.returningUrl = window.location.hash.split('#')[1];
      let token = this.tokenService.getToken();
      if(token == null) {
        this.router.navigate(['/landing']
        , { queryParams: { returnUrl: state.url } });
      }
      // ------ send back to login for null token

      if(this.authData != null){

        if(this.authData.user.role != null && this.authData.user.role.role != undefined ){
          console.log("authData rolesname",this.authData.user.role.role.name);
          this.permissionsArray = this.authData.user.role.permission;
          console.log("authData permission",this.authData.user.role.permission);
          let pathName = this.pathNameFromData;
          this.permissionsArrayFiltered = this.permissionsArray.filter(function(x) {
            return x.name === pathName;
         });
         console.log("this.permissionsArrayFiltered", this.permissionsArrayFiltered);
    
         if(this.permissionsArrayFiltered.length == 1){
         
          this.permissionCreate = this.permissionsArrayFiltered[0].permission.create;
         this.permissionView = this.permissionsArrayFiltered[0].permission.view;
         this.permissionUpdate = this.permissionsArrayFiltered[0].permission.update;
         this.permissionDelete = this.permissionsArrayFiltered[0].permission.delete;
         console.log("permissionCreate ", this.permissionCreate);
         console.log("permissionView ", this.permissionView);
         console.log("permissionUpdate ", this.permissionUpdate);
         console.log("permissionDelete ", this.permissionDelete);
          
        }
  
        if(this.pathNameFromData == 'Kiosk' && this.authData.user.role.role.name != 'company'){
          return  this.router.navigate(["./404-Not-Found"]);
        }
      
        if(this.permissionView == false && this.pathNameFromData != undefined){
          return  this.router.navigate(["./404-Not-Found"]);
        }
        else {
          return this.authenticate(state.url);
        }
        }
      }

      if(this.authData.user.role.role == undefined){
        if(this.authData.user.role.name == "contract_employee"){
          // alert("role is null");
          return  this.router.navigate(["./contractors"]);
        }
      }



      else {
        return this.authenticate(state.url);
      }

    

  }
  
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("canActivateChild next", next);
      console.log("canActivateChild state", state);
      console.log("canActivateChild state.url", state.url);

    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean 
    {
     return this.authenticate(route.path);
      
      // let token = this.tokenService.getToken();
      // if(!token) {
      //   this.router.navigate(['/login']
      //   // , { queryParams: { returnUrl: state.url } }
      //   );
      // }
      // else {
        
      //   return true;
      // }

      // const currentUser = this.authenticationService.currentUserValue;
      // if (currentUser) {
      //     // logged in so return true
      //     return true;
      // }

      // not logged in so redirect to login page with the return url
      
      // return false;
    }

    authenticate(state){
      let token = this.authService.getToken();
      let resetEmail = this.authService.getResetEmail();
      let isReset = this.authService.isResetPasswordAllowed();
      let url = state;
      console.log(url);

      if(url=="/auth/confirmation"){
        if(isReset){
          this.router.navigate(["/request-password"]);
        }
        else if(resetEmail){
          return true;
        }
        else if(!token){
          this.router.navigate(["/"]);
        }
        else {
          return this.authService.checkUserActivation().pipe(
            map(
              (data:any)=>{
               if(data.user.status == "unverified"){
                   return true;
               }
               else {
                 this.router.navigate(["/"]);
               }
              }
            )
          )
        }
          
      }
      else if(url=="/auth/reset-password"){
        if(isReset){
          return true;
        }
        else {
          this.router.navigate(["/"]);
        }
      }

      // tslint:disable-next-line: no-console
      console.log("in guard ",token);
      if(token) {
        // return true;
        return this.authService.checkUserActivation().pipe(
          map(
            (data:any)=>{
             if(data.user.status == "unverified"){
                 this.router.navigate(["/auth/confirmation"]);
             }
             else
             {
               return true;
             }
            },
          ),
          catchError(err => {
            this.router.navigate(["/auth/login"]);
            throw 'error in source. Details: ' + err;
            
          }),
        )
      }
      else {
        this.router.navigate(['/landing'] 
        // , { queryParams: { returnUrl: state.url } }
        );
      }
    }
}
